import React from "react";
import CardGroup from 'react-bootstrap/CardGroup';
import CardsHomePage2 from "../components/CardsHomePage2";
import Typography from '@mui/material/Typography';

import logo_upgraders from '../images/logo_upgraders.png';
import logo_cosmetics from '../images/logo_cosmetics.png';
import logo_fingernails from '../images/logo_fingernails.png';
import logo_accessories from '../images/logo_accessories.png';
import logo_sexToys from '../images/logo_sexToys.png';
import logo_kitchen from '../images/logo_kitchen.png';
import logo_fashion from '../images/logo_fashion.png';
import logo_cars from '../images/logo_cars.png';

import logo_telegram from '../images/logo_telegram.png';


function Hebrew() {
        return (
                <div className="App-header" >
                        <div class="container">

                                <Typography variant="h4" >עלי-אקספרס בטלגרם</Typography>

                                <CardGroup class="text-dark card-group" style={{ justifyContent: "center" }}>

                                        {/* comment example */}

                                        {/* 
                                        <CardsHomePage2
                                                headerText="טלגרם"
                                                mainLogo={logo_telegram}
                                                cardText="רוצים לחסוך זמן? כאן נמצאים כל הערוצים במרוכז"
                                                url="https://t.me/addlist/LS2G8swGHQUwOGM0"
                                        />
                                        */}

                                        <CardsHomePage2
                                                headerText="נייליסטיות"
                                                mainLogo={logo_fingernails}
                                                cardText="בייס, לקים, מדבקות, פצירות, צבעים, מרקמים, מקדחים וכל מה שיש לתחום הנייל להציע"
                                                url="https://t.me/upgradeFingernails"
                                        />

                                        <CardsHomePage2
                                                headerText="קוסמטיקאיות"
                                                mainLogo={logo_cosmetics}
                                                cardText="ציוד מקצועי למגוון תחומי הקוסמטיקה במחירים משתלמים"
                                                url="https://t.me/cosmeticsUpgraders"
                                        />

                                        <CardsHomePage2
                                                headerText="המשדרגים"
                                                mainLogo={logo_upgraders}
                                                cardText="הום סטיילינג, תאורה, איחסון, אלקטרוניקה, אבטחה, ומוצרים שישנו את הבית בסגנון האישי שלכם"
                                                url="https://t.me/upgraders_israel"
                                        />

                                        <CardsHomePage2
                                                headerText="אופנה ואקססוריז"
                                                mainLogo={logo_accessories}
                                                cardText="טבעות, עגילים, שרשראות וצמידים, תיקים, ארנקים וכיסויים לטלפונים"
                                                url="https://t.me/accessoriesAndJewlery"
                                        />

                                        <CardsHomePage2
                                                headerText="אביזרים לרכב"
                                                mainLogo={logo_cars}
                                                cardText="הדברים הקטנים שעושים את ההבדל בין סתם רכב לרכב מותאם אישית, מתאים גם לאופנוענים"
                                                url="https://t.me/CarUpgrade"
                                        />

                                        <CardsHomePage2
                                                headerText="לתינוקות וילדים"
                                                mainLogo={logo_cars}
                                                cardText="מוצרים לאמהות, תינוקות וילדים"
                                                url="https://t.me/upgradeMyBaby"
                                        />


                                </CardGroup>




                                <div class="container" style={{ marginTop: 40 }}>
                                        <a
                                                className="App-link"
                                                href="https://s.click.aliexpress.com/e/_AAdgIV"
                                                target="_blank" // open in a new tab
                                                rel="noopener noreferrer"
                                        >
                                                <Typography variant="h4">לאתר עלי אקספרס</Typography>

                                        </a>
                                </div>
                        </div>
                </div>
        );
}

export default Hebrew;
